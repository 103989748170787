import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';
// 引入请求
import req from './utils/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$http = req
// 引入antd
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)
import VueAppend from 'vue-append'
Vue.use(VueAppend)
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)


Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store, //使用store vuex状态管理
  render: h => h(App),
}).$mount('#app')
