<template>
  <div id="app">
    <div class="header" v-if="showHerder">
      <headerVue :user="user"></headerVue>
    </div>
    <div class="bigBox">
      <div class="menu" v-if="showmenuVue">
        <menuVue :user="user" :path="path"></menuVue>
      </div>
      <div
        class="main"
        :style="
          showHerder ? 'min-height: calc(100vh - 50px);' : 'min-height: 100vh'
        "
      >
        <div class="watchBox" :style="!showHerder ? '' : 'border-radius: 4px;'">
          <router-view @openDialog="openDialog" ref="mainBox" />
        </div>
      </div>
    </div>
    <div class="footer" v-if="showFooter">
      ©2023 All Rights Reserved 京ICP备2023005450号 北京研斯数据科技有限公司
    </div>
    <!-- 接评弹窗 -->
    <a-modal
      :title="dialogMsg.isSureOrNo === 1 ? '确认接评' : '拒绝接评'"
      :visible="isOpenDialog"
      :keyboard="false"
      @ok="sure"
      :cancel-button-props="{ style: { display: 'none' } }"
      okText="确认"
      :width="500"
      cancelText="关闭"
      @cancel="isOpenDialog = false"
      :footer="null"
    >
      <div class="dialogMain nowidth">
        <div class="lunwenTitle">
          {{ dialogMsg.isSureOrNo === 1 ? "确认" : "拒绝" }}接收论文《{{
            dialogMsg.thesisName
          }}》的评审？
        </div>
        <div class="main">
          <div
            class="item"
            style="margin-bottom: 10px"
            v-if="
              dialogMsg.zhuanjiazhicheng && dialogMsg.zhuanjialeix.length > 0
            "
          >
            <div class="label">专家类型至少满足其一:</div>
            <div class="value">
              <a-tag
                color="#3d81c9"
                v-for="item in dialogMsg.zhuanjialeix"
                :key="item"
                >{{ item }}</a-tag
              >
            </div>
          </div>
          <div class="item">
            <div
              class="label"
              style="text-align: left"
              v-if="
                dialogMsg.zhuanjiazhicheng &&
                dialogMsg.zhuanjiazhicheng.length > 0
              "
            >
              专家职称至少满足其一:
            </div>
            <div class="value">
              <a-tag
                color="#3d81c9"
                v-for="item in dialogMsg.zhuanjiazhicheng"
                :key="item"
                >{{ item }}</a-tag
              >
            </div>
          </div>
        </div>
        <div v-if="dialogMsg.isSureOrNo !== 1">
          <div class="lunwenTitle" style="margin: 10px 0">
            请填写拒评的理由，方便我们更合理的分配论文。
          </div>

          <a-textarea
            v-model="rejectionReason"
            placeholder="请输入拒评的理由"
            :auto-size="{ minRows: 3, maxRows: 6 }"
          />
        </div>
      </div>
      <div class="modalFooter">
        <!-- <a-button @click="isOpenDialog = false">关闭</a-button> -->
        <a-button @click="sure" type="primary">确认</a-button>
      </div>
    </a-modal>
    <!--    <a-modal-->
    <!--      title="填写信息"-->
    <!--      :visible="isOpenUserMsg"-->
    <!--      @ok="saveUserMsg"-->
    <!--      okText="保存"-->
    <!--      cancelText="关闭"-->
    <!--      @cancel="isOpenUserMsg = false"-->
    <!--    >-->
    <!--      <div class="dialogMain">-->
    <!--        <div class="main">-->
    <!--          <div class="item">-->
    <!--            <div class="label">职称:</div>-->
    <!--            <div class="value">-->
    <!--              <a-select-->
    <!--                v-model="workInfo.professionalTitle"-->
    <!--                style="width: 200px"-->
    <!--                placeholder="请选择职称"-->
    <!--              >-->
    <!--                &lt;!&ndash; @change="professionalTitleChange" &ndash;&gt;-->
    <!--                <a-select-option :value="1"> 教授 </a-select-option>-->
    <!--                <a-select-option :value="2"> 副教授 </a-select-option>-->
    <!--                <a-select-option :value="3"> 正高级 </a-select-option>-->
    <!--                <a-select-option :value="4"> 副高级 </a-select-option>-->
    <!--              </a-select>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="item">-->
    <!--            <div class="label">专家类别:</div>-->
    <!--            <div class="value">-->
    <!--              <a-select-->
    <!--                v-model="workInfo.expertType"-->
    <!--                style="width: 200px"-->
    <!--                placeholder="请选择专家类别"-->
    <!--              >-->
    <!--                &lt;!&ndash; @change="expertTypeChange" &ndash;&gt;-->
    <!--                <a-select-option :value="1"> 硕导 </a-select-option>-->
    <!--                <a-select-option :value="2"> 博导 </a-select-option>-->
    <!--                <a-select-option :value="3"> 硕博导 </a-select-option>-->
    <!--              </a-select>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="item">-->
    <!--            <div class="label">研究方向1:</div>-->
    <!--            <div class="value">-->
    <!--              <a-input-->
    <!--                style="width: 200px !important"-->
    <!--                v-model="workInfo.researchFields1"-->
    <!--              />-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        <div class="item">-->

    <!--            <div class="label">学科类型:</div>-->
    <!--            <div class="value">-->
    <!--              <a-select-->
    <!--                  style="width: 200px"-->
    <!--                  v-model="workInfo.firstLevelSubjectCode"-->
    <!--                  @change="getSecondSubjectCodeList"-->
    <!--                  placeholder="请选择一级学科"-->
    <!--              >-->
    <!--                <a-select-option-->
    <!--                    v-for="(item, index) in firstSubjectCodeList"-->
    <!--                    :key="index"-->
    <!--                    :value="item.code"-->
    <!--                >-->
    <!--                  {{ item.name }}-->
    <!--                </a-select-option>-->

    <!--              </a-select>-->
    <!--            </div>-->

    <!--        </div>-->
    <!--          <div class="item">-->

    <!--            <div class="label">二级学科:</div>-->
    <!--            <div class="value">-->
    <!--              <a-select-->
    <!--                  style="width: 200px"-->
    <!--                  v-model="workInfo.secondaryLevelSubjectCode"-->
    <!--                  placeholder="请选择二级"-->
    <!--              >-->
    <!--                &lt;!&ndash; , { rules: [{ required: true, message: '请选择二级学科代码!' }] }, &ndash;&gt;-->
    <!--                <a-select-option-->
    <!--                    v-for="(item, index) in secondSubjectCodeList"-->
    <!--                    :key="index"-->
    <!--                    :value="item.code"-->
    <!--                >-->
    <!--                  {{ item.name }}-->
    <!--                </a-select-option>-->
    <!--              </a-select>-->
    <!--            </div>-->

    <!--          </div>-->
    <!--          <div class="item">-->
    <!--            <div class="label">电子邮箱:</div>-->
    <!--            <div class="value">-->
    <!--              <a-input-->
    <!--                  style="width: 200px !important"-->
    <!--                  v-model="workInfo.email"-->
    <!--              />-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--  </div>-->
    <!--    </a-modal>-->
  </div>
</template>

<script>
import menuVue from "./components/menu.vue";
import headerVue from "./components/header.vue";
import huibi from "./pages/jiePingDan/components/index.vue";

export default {
  name: "App",
  components: {
    menuVue,
    headerVue,
    huibi,
  },
  created() {
    let token = window.localStorage.getItem("token");
    if (!token) {
      this.$router.push("/");
    }

    // this.$http("get", "/personal-information/subjects?type=1").then((res) => {
    //   this.firstSubjectCodeList = res.data;
    // });
  },
  data() {
    return {
      firstSubjectCodeList: [],
      secondSubjectCodeList: [],
      isOpenDialog: false,
      dialogMsg: {},
      user: {},
      showmenuVue: false,
      showHerder: true,
      isLoginPage: false,
      path: "",
      showFooter: true,
      workInfo: {
        firstLevelSubjectCode: "",
        secondaryLevelSubjectCode: "",
        professionalTitle: "",
        expertType: "",
        researchFields1: "",
        // email: "",
      },

      // isOpenUserMsg: false,
      myTimer: null,
      rejectionReason: "",
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   window.addEventListener("mousedown", this.myTimeOut);
    // });
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    getSecondSubjectCodeList(code) {
      this.$http(
        "get",
        "/personal-information/subjects?type=2&parentCode=" + code
      ).then((res) => {
        this.secondSubjectCodeList = res.data;
      });
    },

    openDialog(msg) {
      console.log(msg);

      this.dialogMsg = msg;
      if (msg.reviewRequires.receiveExpert) {
        this.dialogMsg.zhuanjialeix =
          msg.reviewRequires.receiveExpert.expertTypes;
        this.dialogMsg.zhuanjiazhicheng =
          msg.reviewRequires.receiveExpert.professionalTitles;
      }
      this.isOpenDialog = true;
    },
    sure() {
      if (this.dialogMsg.isSureOrNo === 1) {
        this.$http(
          "PATCH",
          `/review-orders/${this.dialogMsg.reviewOrderId}/accept`
        ).then((res) => {
          // console.log("确认");
          if (res.code != 200) {
            if (res.code == 21101) {
              this.getUserMsg();
            }
            this.$message.error(res.msg);
          } else {
            this.$message.success("接评成功！");
          }
        });
      } else {
        if (!this.rejectionReason) {
          this.$message.error("请输入拒绝接评理由！");
          return;
        }
        this.$http(
          "PATCH",
          `/review-orders/${this.dialogMsg.reviewOrderId}/not-accept`,
          { rejectionReason: this.rejectionReason }
        ).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.msg);
          } else {
            this.$message.success("拒绝成功！");
          }
        });
      }
      setTimeout(() => {
        this.$refs.mainBox.showList();
      }, 500);
      this.isOpenDialog = false;
    },
    // getUserMsg() {
    //   this.$http("get", "/personal-information").then((res) => {
    //     let information = res.data.workInfo;
    //
    //  if (information.firstLevelSubjectCode== null || information.researchFields1==null ||information.expertType==null ||information.professionalTitle==null
    //  || res.data.basicInfo.sex==null || res.data.basicInfo.email==null){
    //      this.$alert('请完善个人信息', '系统提示', {
    //        confirmButtonText: '确定',
    //        callback: () => {
    //          this.$router.push('/geRenZiLiao/');
    //        }
    //      });
    //
    //  }
    //
    //   });
    // },
    saveUserMsg() {
      this.$http(
        "put",
        "/review-orders/required-information",
        this.workInfo
      ).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功！");
          this.isOpenUserMsg = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    professionalTitleChange(val) {
      this.workInfo.professionalTitle = val;
    },
    expertTypeChange(val) {
      this.workInfo.expertType = val;
    },
    myTimeOut() {
      const that = this;
      // 因为登录界面不需要进行校验，所以将它排除在外
      let path = ["/login", "/"];
      if (!path.includes(this.$route.path)) {
        // 首先需要清楚原有的计时器，如果不清除的话无法做到重置
        clearTimeout(this.myTimer);

        // 这里设置一个新的计时器，并赋给myTimer
        this.myTimer = setTimeout(() => {
          // 在这执行登出的操作，具体情况参考您的系统中
          this.$http("post", "/logout").then((res) => {
            if (res.code == 200) {
              window.localStorage.setItem("token", "");
              that.$router.push("/");
            }
          });
          // 设置弹窗，提示过期
          window.alert("您的登录已经过期！");
          // 三十分钟
        }, 1000 * 60 * 60);
      }
    },
  },
  watch: {
    $route(to) {
      this.path = to.path;
      if (
        to.path === "/watchPdf" ||
        to.path === "/pingyueYiJianShu" ||
        to.path === "/newPdfWatch"
      ) {
        this.showmenuVue = false;
        this.showHerder = true;
      } else {
        this.showmenuVue = true;
      }
      if (to.path === "/pingyueYiJianShu" || to.path === "/newPdfWatch") {
        this.showHerder = false;
      }
      if (
        to.path == "/checkLoginMode" ||
        to.path == "/login" ||
        to.path == "/"
      ) {
        this.showmenuVue = false;
        this.showHerder = false;
        this.isLoginPage = true;
      }
      if (
        to.path == "/pingyueYiJianShu" ||
        to.path == "/login" ||
        to.path == "/watchPdf" ||
        to.path === "/newPdfWatch"
      ) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
      if (to.path === "/index") {
        this.showHerder = true;
        this.showFooter = true;
      }
      // this.myTimeOut();
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  max-width: 100vw;
  min-height: 100%;
  background-color: #fff;
  overflow: hidden;
}
.header {
  height: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.bigBox {
  display: flex;
  width: 100vw;
  // height: auto;
  // margin-top: 10px;
  background-color: #f1f5f7;
  .menu {
    flex: 2;
    // min-height: 100vh;
    background-color: #fff;
    overflow: hidden;
    border-right: 1px solid rgb(229, 229, 229);
    max-width: 208px;
    min-width: 208px;
    // background-color: red;
  }
  .main {
    flex: 9;
    box-sizing: border-box;
    overflow: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.dialogMain {
  position: relative;
  text-align: left;
  font-size: 16px;
  padding: 10px 24px;
  .item {
    color: rgba(67, 92, 119, 1);
    overflow: hidden;
    .label {
      float: left;
      text-align: right;
      width: 30%;
      padding-right: 10px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      color: rgba(67, 92, 119, 0.6);
      box-sizing: border-box;
    }
    .value {
      float: left;
      width: 60%;
      text-align: left;
      overflow: hidden; // 超出部分隐藏
      text-overflow: ellipsis; // 超出部分显示...
      white-space: nowrap; // 文本强制一行显示
      box-sizing: border-box;
      ::v-deep.ant-input {
        width: 100% !important;
        background-color: #fff !important;
      }
    }
  }
}
.nowidth {
  .item {
    .label {
      text-align: left;
      width: fit-content;
    }
  }
}
.watchBox {
  height: 100%;
  // padding: 24px 24px 0 24px;
  // padding: 16px;
  // background: rgba(255, 255, 255, 1);
  // box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
}
.footer {
  text-align: center;
  height: 80px;
  line-height: 80px;
  background-color: rgba(241, 245, 247, 1);
  // color: rgba(61, 129, 201, 1);
  font-size: 14px;
}
body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
a:hover {
  opacity: 0.8;
}
.dialogBtn:hover {
  opacity: 0.8;
}
.ant-table-thead > tr > th {
  background: rgba(67, 92, 119, 0.05) !important;
  border-bottom: none !important;
  color: rgba(67, 92, 119, 1) !important;
}
.ant-table table {
  border-collapse: collapse !important;
  border: none;
}
.ant-table-tbody > tr {
  border-bottom: 1px solid rgba(223, 232, 245, 1) !important;
}
.dialogClose:hover {
  opacity: 0.8;
}
/* .watchBox .header .leftBox img {
  margin-right: 15px;
} */
.lunwenTitle {
  margin: 10px 0 10px 0;
  font-size: 14px;
}

.modalFooter {
  border-top: 1px solid #ccc;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  .okBtn {
    margin-left: 50px;
  }
}
</style>
