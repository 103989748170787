<template>
  <div>
    <!-- <a-row v-if="requireTagsData.institution.tags.length > 0">
      <a-row> 学校要求（{{ requireTagsData.institution.condition }}）： </a-row>
      <a-row
        ><span
          class="tagsCss"
          v-for="(tag, index) in requireTagsData.institution.tags"
          style="margin-left: 0px"
          :key="index"
          >{{ tag }}</span
        ></a-row
      >
      <a-row style="margin-top: -24px">
        <a-divider />
      </a-row>
    </a-row> -->

    <a-row v-if="requireTagsData.expert.tags.length > 0">
      <a-row>
        专家要求
        <span v-show="requireTagsData.expert.condition"
          >（{{ requireTagsData.expert.condition }}）</span
        >：
      </a-row>
      <a-row
        ><span
          class="tagsCss"
          style="margin-left: 0px"
          v-for="(tag, index) in requireTagsData.expert.tags"
          :key="index"
          >{{ tag }}</span
        ></a-row
      >
      <a-row style="margin-top: -24px" v-if="showdivider">
        <a-divider />
      </a-row>
    </a-row>

    <!-- <a-row v-if="requireTagsData.exclusion.tags.length > 0">
      回避：<span
        class="tagsCss"
        style="margin-left: 0px"
        v-for="(tag, index) in requireTagsData.exclusion.tags"
        :key="index"
        >{{ tag }}</span
      >
      <a-row style="margin-top: -24px">
        <a-divider />
      </a-row>
    </a-row> -->
    <a-row v-if="requireTagsData.otherDesc">
      其他： {{ requireTagsData.otherDesc }}
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    reviewRequire: { default: {} },
    disableExclusion: Boolean,
    showdivider: { default: true },
  },
  computed: {
    requireTagsData() {
      console.log(222);
      const institution = { tags: [], condition: null };
      const expert = { tags: [], condition: null };
      const exclusion = { tags: [] };
      console.log(this.reviewRequire, 999);
      if (!this.reviewRequire)
        return { institution, expert, exclusion, otherDesc: null };
      const receiveInstitution = this.reviewRequire.receiveInstitution;
      console.log(receiveInstitution, 912121299);
      if (receiveInstitution) {
        if (receiveInstitution.schoolLevels) {
          institution.tags.push(...receiveInstitution.schoolLevels);
        }
        if (receiveInstitution.otherSchoolLevel) {
          institution.tags.push("其他：" + receiveInstitution.otherSchoolLevel);
        }
        if (receiveInstitution.subjectLevels) {
          institution.tags.push(...receiveInstitution.subjectLevels);
        }
        if (receiveInstitution.otherSubjectLevel) {
          institution.tags.push(
            "其他：" + receiveInstitution.otherSubjectLevel
          );
        }
        if (receiveInstitution.condition) {
          institution.condition =
            receiveInstitution.condition === "ALL_MATCH"
              ? "同时具备"
              : "符合其一";
        }
      }
      const receiveExpert = this.reviewRequire.receiveExpert;
      if (receiveExpert) {
        if (receiveExpert.expertTypes) {
          expert.tags.push(...receiveExpert.expertTypes);
        }
        if (receiveExpert.professionalTitles) {
          expert.tags.push(...receiveExpert.professionalTitles);
        }
        if (receiveExpert.condition) {
          expert.condition =
            receiveExpert.condition === "ALL_MATCH" ? "同时具备" : "符合其一";
        }
      }
      const exclusionRule = this.reviewRequire.exclusionRule;
      if (exclusionRule && !this.disableExclusion) {
        if (exclusionRule.provinces) {
          exclusion.tags.push(...exclusionRule.provinces);
        }
        if (exclusionRule.institutions) {
          exclusion.tags.push(...exclusionRule.institutions);
        }
      }
      console.log({
        institution,
        expert,
        exclusion,
        otherDesc: this.reviewRequire.otherDesc,
      });
      return {
        institution,
        expert,
        exclusion,
        otherDesc: this.reviewRequire.otherDesc,
      };
    },
  },
};
</script>
<style scoped>
.el-tag {
  white-space: normal;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}
.tagsCss {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  background-color: #3d81c9;
  color: #fff;
  border-radius: 4px;
  padding: 0 7px;
  line-height: 20px;
  margin-right: 10px;
}
</style>
