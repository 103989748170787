// 导入组件
import Vue from 'vue';
import Router from 'vue-router';

// 启用路由
Vue.use(Router);

// 导出路由
export default new Router({
    routes: [
        {
            path: '/',
            name: 'checkLoginMode',
            component: () => import('../pages/checkLoginMode/index')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../pages/login/index')
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('../pages/index/index')
        },
        {
            path: '/jiePingDan',
            name: 'jiePingDan',
            component: () => import('../pages/jiePingDan/index')
        },
        {
            path: '/feiYongJieSuan',
            name: 'feiYongJieSuan',
            component: () => import('../pages/feiYongJieSuan/index')
        },
        {
            path: '/geRenZiLiao',
            name: 'geRenZiLiao',
            component: () => import('../pages/geRenZiLiao/index')
        },
        {
            path: '/zhangHaoAnQuan',
            name: 'zhangHaoAnQuan',
            component: () => import('../pages/zhangHaoAnQuan/index')
        },
        {
            path: '/watchPdf',
            name: 'watchPdf',
            component: () => import('../pages/watchPdf/index')
        },
        {
            path: '/pingyueYiJianShu',
            name: 'pingyueYiJianShu',
            component: () => import('../components/pingyueYiJianShu')
        },
        {
            path: '/setTiXianPwd',
            name: 'setTiXianPwd',
            component: () => import('../pages/feiYongJieSuan/setTiXianPwd')
        },
        {
            path: '/shengQingTiXian',
            name: 'shengQingTiXian',
            component: () => import('../pages/feiYongJieSuan/shengQingTiXian')
        },
        {
            path: '/newBankCard',
            name: 'newBankCard',
            component: () => import('../pages/feiYongJieSuan/newBankCard')
        },
        {
            path: '/bankCardAdmin',
            name: 'bankCardAdmin',
            component: () => import('../pages/feiYongJieSuan/bankCardAdmin')
        },
        {
            path: '/newPdfWatch',
            name: 'newPdfWatch',
            component: () => import('../pages/newPdfWatch/index')
        },
        {
            path: '/shimingrenzheng',
            name: 'shimingrenzheng',
            component: () => import('../pages/shimingrenzheng/shimingrenzheng')
        },
        {
            path: '/dianZiQianMing',
            name: 'dianZiQianMing',
            component: () => import('../pages/dianZiQianMing/dianZiQianMing')
        },
        {
            path: '/yanJiuFangXiang',
            name: 'yanJiuFangXiang',
            component: () => import('../pages/yanJiuFangXiang/index')
        }
    ]
})
