<template>
  <div class="menuBox">
    <div class="userMsg">
      <!-- <div class="compontCss userIcon">
        <a-avatar
          shape="square"
          size="large"
          :style="{ backgroundColor: '#3d81c9', verticalAlign: 'middle' }"
        >
          {{ user.name }}
        </a-avatar>
      </div> -->
      <div class="compontCss userName">
        <div class="name textOneLine">{{ user.name }}</div>
        <div class="daxue textOneLine">{{ user.institutionName }}</div>
        <div class="renzheng">
          <img
            v-if="user.isAuthentication"
            src="../assets/yirenzheng.png"
            alt=""
          />
          <img v-else src="../assets/weirenzheng.png" alt="" />
          <p>{{ user.isAuthentication ? "已实名" : "未实名" }}</p>
        </div>
      </div>
    </div>
    <div class="menuList">
      <div
        v-for="(item, index) in menuList"
        @click="checkItem(item)"
        @mouseenter="enter(item.id)"
        @mouseleave="leave()"
        :key="index"
        :class="
          checkId == item.id || moveId == item.id
            ? 'menuItem acitve'
            : 'menuItem'
        "
      >
        <!-- <a-icon :type="item.icon" style="margin-right: 17px" theme="filled" /> -->
        <img
          :src="
            checkId == item.id || moveId == item.id
              ? item.iconActive
              : item.icon
          "
          alt=""
        />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          id: 1,
          name: "首页",
          icon: require("../assets/index.png"),
          iconActive: require("../assets/index.png"),
          path: "/index",
        },
        {
          id: 2,
          name: "接评单",
          icon: require("../assets/jpd.png"),
          iconActive: require("../assets/jpd.png"),
          path: "/jiePingDan",
        },
        {
          id: 3,
          name: "费用结算",
          icon: require("../assets/fyjs.png"),
          iconActive: require("../assets/fyjs.png"),
          path: "/feiYongJieSuan",
        },
        {
          id: 4,
          name: "个人资料",
          icon: require("../assets/grzl.png"),
          iconActive: require("../assets/grzl.png"),
          // iconActive: require("../assets/grzl_active.png"),
          path: "/geRenZiLiao",
          showSubMenu: false,
        },
        {
          id: 7,
          name: "研究方向",
          icon: require("../assets/yjfx.png"),
          iconActive: require("../assets/yjfx.png"),
          path: "/yanJiuFangXiang",
        },
        {
          id: 5,
          name: "实名认证",
          icon: require("../assets/zhaq.png"),
          iconActive: require("../assets/zhaq.png"),
          path: "/shimingrenzheng",
        },
        {
          id: 6,
          name: "电子签名",
          icon: require("../assets/grzl.png"),
          iconActive: require("../assets/grzl.png"),
          // iconActive: require("../assets/grzl_active.png"),
          path: "/dianZiQianMing",
        },
        // {
        //   id: 5,
        //   name: "账号安全",
        //   icon: require("../assets/zhaq.png"),
        //   iconActive: require("../assets/zhaq_active.png"),
        //   path: "/zhangHaoAnQuan",
        // },
      ],
      checkId: 1,
      user: {},
      moveId: "",
      isComplete: true,
      flag:1,
      item:{
        id:1,
        path:'/index'
      }
    };
  },
  props: {
    path: { default: "" },
  },
  methods: {
    toggleSubMenu(id) {
      this.menuList.forEach(item => {
        if (item.id === id) {
          item.showSubMenu = !item.showSubMenu;
        } else {
          item.showSubMenu = false;
        }
      });
    },
    checkItem(item) {
      this.checkIfUserMsg();
      if (item && item.id) {
        this.checkId = item.id;
      }
      item.showSubMenu = !item.showSubMenu;
      if (this.$route.path == item.path) return;
      if (this.flag==2){
        this.getResearchAndSubject()
      }
      if (this.isComplete) {
        this.$router.push(item.path);
      }

      this.getUser();
    },
    enter(id) {
      this.moveId = id;
    },
    leave() {
      this.moveId = "";
    },
    getCheckId(id) {
      switch (id) {
        case "/index":
          this.checkId = 1;
          break;
        case "/jiePingDan":
          this.checkId = 2;
          break;
        case "/feiYongJieSuan":
          this.checkId = 3;
          break;
        case "/geRenZiLiao":
          this.checkId = 4;
          break;
        case "/zhangHaoAnQuan":
          this.checkId = 5;
          break;
        case "/setTiXianPwd":
          this.checkId = 3;
          break;
        case "/shengQingTiXian":
          this.checkId = 3;
          break;
        case "/newBankCard":
          this.checkId = 3;
          break;
        case "/bankCardAdmin":
          this.checkId = 3;
          break;
        case "/dianZiQianMing":
          this.checkId = 6;
          break;
        case "/yanJiuFangXiang":
          this.checkId = 7;
          break;
      }
    },
    getUser() {
      this.$http("get", "/getInfo").then((res) => {
        this.$store.state.userMsg = res.user;
        this.user = res.user;
      });
    },
    checkIfUserMsg() {

      this.$http("get", "/personal-information").then((res) => {

        let information = res.data.workInfo;
        if (
          !information.expertType && information.expertType!==0 ||
          !information.professionalTitle ||
          !res.data.basicInfo.email
        ) {
          this.isComplete = false;
          this.$alert("请完善个人信息", "系统提示", {
            confirmButtonText: "确定",
            callback: () => {
              if (this.$route.path !== "/geRenZiLiao") {
                this.$router.push("/geRenZiLiao");
              }
              this.checkId = 4;
            },
          });
        }
        else {
          this.isComplete = true;
          this.flag =2
        }
      });
    },
    getResearchAndSubject() {
      this.$http(
          "get",
          "/personal-information/getSubjectAndResearchInterests"
      ).then((res) => {
        console.log("------------------------------------------------",res.data)
     if (res.data&& res.data.professionalDegreeForm.length===0 && res.data.subjectForm.length===0 ){
          this.$alert("请完善研究方向", "系统提示", {
            confirmButtonText: "确定",
            callback: () => {
              if (this.$route.path !== "/yanJiuFangXiang") {
                this.$router.push("/yanJiuFangXiang");
              }
              this.checkId = 7;
            },
          });
        }
      })
    },
  },

  watch: {
    path(val) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].path == val) {
          this.checkId = this.menuList[i].id;
        }
      }
    },
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.getUser();
    }
    this.checkItem(this.item);
    this.getCheckId(this.path);
  },
};
</script>

<style lang="scss" scoped>
.menuBox {
  position: fixed;
  top: 50px;
  left: 0;
  width: 208px;
  // border-radius: 10px;
  // margin: auto;
  .userMsg {
    position: relative;
    top: 0;
    width: 90%;
    max-width: 90%;
    height: 100px;
    margin: auto;
    .compontCss {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70px;
      height: 70px;
    }
    .userIcon {
      border-radius: 50%;
      // background-color: #fff;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 62px;
        height: 62px;
        line-height: 62px;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .userName {
      position: relative;
      // top: 40px;
      // margin-left: 10px;
      width: 100%;
      text-align: center;
      .name {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: #212b42;
      }
      .daxue {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #435c77;
        margin-top: 6px;
      }
      .textOneLine {
        overflow: hidden; //超出部分隐藏
        text-overflow: ellipsis; //超出部分显示...
        white-space: nowrap;
        text-align: center;
        margin-left: -25px;
      }
      .renzheng {
        position: absolute;
        top: 15px;
        right: 0;
        text-align: center;
        font-size: 10px;
        img {
          display: block;
          width: 20px;
          margin: 0 auto;
        }
      }
    }
  }
  .userMsg::after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background-color: #fff;
    content: "";
    opacity: 0.2;
  }
  .menuList {
    position: relative;
    top: 0;
    width: 90%;
    max-width: 90%;
    margin: auto;
    .menuItem {
      padding: 10px 0 10px 45px;
      box-sizing: border-box;
      font-size: 14px;
      color: #435c77;
      cursor: pointer;
      margin: 0px 0px 10px 0;
      img {
        position: relative;
        top: -1px;
        width: 14px;
        margin-right: 12px;
      }
    }
    .acitve {
      background: #f1f5f7;
      border-radius: 10px;
      color: #435c77;
    }
  }
}
</style>
